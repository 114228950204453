import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8b7dfb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-container" }
const _hoisted_2 = { class: "cert-container" }
const _hoisted_3 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductInfo = _resolveComponent("ProductInfo")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_FooterInfo = _resolveComponent("FooterInfo")!
  const _component_CopyrightInfo = _resolveComponent("CopyrightInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProductInfo, {
      list: _ctx.state.list,
      activetype: _ctx.state.activetype
    }, null, 8, ["list", "activetype"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.dataCerts, (item) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_image, {
            src: `https://gwht.scrzhy.com/${item.savePath}`,
            width: "100%",
            height: "100%"
          }, null, 8, ["src"]),
          _createElementVNode("div", null, _toDisplayString(item.materialName), 1)
        ]))
      }), 256))
    ]),
    _createVNode(_component_FooterInfo),
    _createVNode(_component_CopyrightInfo)
  ]))
}