
import { defineComponent, reactive, onMounted } from 'vue'
import ProductInfo from '@/components/product/ProductInfo.vue'
import FooterInfo from '@/components/common/FooterInfo.vue'
import CopyrightInfo from '@/components/common/CopyrightInfo.vue'
import { baseApiUrl } from '@/config/apiConfig'
import { getBannerByIdApi } from '@/api/api'
import { Image } from 'ant-design-vue'

interface ClassifyInfo {
  code: string
  label: string
}

export default defineComponent({
  name: 'Cert',
  components: {
    ProductInfo,
    FooterInfo,
    CopyrightInfo,
    AImage: Image
  },
  setup() {
    const state = reactive<{
      dataCerts: string
      activeCode: string
      classifyList: ClassifyInfo[]
      list: any[]
      arr: any[]
      page: {
        current: number
        size: number
      }
      path: string
      activetype: number
      total: number
    }>({
      dataCerts: '',
      activeCode: '1',
      classifyList: [],
      list: [],
      arr: [],
      page: {
        current: 1,
        size: 10
      },
      path: '',
      activetype: 1,
      total: 0
    })

    onMounted(() => {
      state.path = baseApiUrl
      getBannerById()
    })
    const getBannerById = async () => {
      let res = await getBannerByIdApi(9)
      if (res.data.rows && res.data.rows.length) {
        state.dataCerts = res.data.rows
      }
    }

    return {
      state
    }
  }
})
